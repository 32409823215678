<template>
  <!-- Content -->
  <b-row class="justify-content-md-center">
    <b-col cols="12" md="12">
      <!-- ** ORDER ** -->
      <h2 class="mb-3 ml-1">
        Comprar {{ type }}
      </h2>
      <b-card v-if="item" no-body>
        <b-card-body>
          <div class="p-2">
            <!-- Element Name -->
            <h3 class="mb-2">
              Sobre el producto
            </h3>
            <p>
              Nombre del elemento:
              <b>{{ item.name[currentLocale] || Object.values(item.name)[0] }}</b>
            </p>
            <p>
              Precio del elemento: <b>{{ item.price / 100 }} €</b>
            </p>
          </div>
        </b-card-body>
      </b-card>
      <b-row class="justify-content-md-center">
        <b-col cols="12" md="6">
          <b-card v-if="item" no-body>
            <b-card-body>
              <div class="p-2">
                <h3 class="mb-2">
                  Datos de facturación
                </h3>
                <!-- Data -->
                <b-input-group class="mb-2 ml-3 w-40 d-inline-block">
                  <p>Nombre</p>
                  <b-form-input
                    v-model="dataToSend.name"
                    type="text"
                    :disabled="disabled"
                    class="w-100"
                    placeholder="Escribe aquí el nombre..."
                  />
                </b-input-group>
                <b-input-group class="mb-2 ml-3 w-40 d-inline-block">
                  <p>Apellido</p>
                  <b-form-input
                    v-model="dataToSend.surname"
                    type="text"
                    :disabled="disabled"
                    class="w-100"
                    placeholder="Escribe aquí el apellido..."
                  />
                </b-input-group>
                <b-input-group class="mb-2 ml-3 w-40 d-inline-block">
                  <p>Numero de teléfono</p>
                  <b-form-input
                    v-model="dataToSend.phone"
                    type="text"
                    :disabled="disabled"
                    class="w-100"
                    placeholder="Escribe aquí el teléfono..."
                  />
                </b-input-group>
                <b-input-group class="mb-2 ml-3 w-40 d-inline-block">
                  <p>Correo eléctronico</p>
                  <b-form-input
                    v-model="dataToSend.mail"
                    type="email"
                    :disabled="disabled"
                    class="w-100"
                    placeholder="Escribe aquí el correo eléctronico..."
                  />
                </b-input-group>
                <b-input-group class="mb-2 ml-3 w-25 d-inline-block">
                  <p>Provincia</p>
                  <b-form-input
                    v-model="dataToSend.province"
                    type="text"
                    :disabled="disabled"
                    class="w-100"
                    placeholder="Escribe aquí la provincia..."
                  />
                </b-input-group>
                <b-input-group class="mb-2 ml-3 w-25 d-inline-block">
                  <p>Ciudad</p>
                  <b-form-input
                    v-model="dataToSend.city"
                    type="text"
                    :disabled="disabled"
                    class="w-100"
                    placeholder="Escribe aquí la ciudad..."
                  />
                </b-input-group>
                <b-input-group class="mb-2 ml-3 w-25 d-inline-block">
                  <p>Dirección</p>
                  <b-form-input
                    v-model="dataToSend.address"
                    type="text"
                    :disabled="disabled"
                    class="w-100"
                    placeholder="Escribe aquí la dirección..."
                  />
                </b-input-group>
                <b-input-group class="mb-2 ml-3 w-40 d-inline-block">
                  <p>CIF/DNI</p>
                  <b-form-input
                    v-model="dataToSend.cif"
                    type="text"
                    :disabled="disabled"
                    class="w-100"
                    placeholder="Escribe aquí el CIF/DNI..."
                  />
                </b-input-group>
                <b-input-group class="mb-2 ml-3 w-40 d-inline-block">
                  <p>Codigo postal</p>
                  <b-form-input
                    v-model="dataToSend.postal"
                    type="text"
                    :disabled="disabled"
                    class="w-100"
                    placeholder="Escribe aquí el codigo postal..."
                  />
                </b-input-group>
              </div>
              <b-button
                v-if="nextStep === false"
                class="ml-5"
                variant="primary"
                @click="handleNextStep"
              >
                Siguiente
              </b-button>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col col="12" md="6">
          <b-card v-if="item" no-body>
            <b-card-body>
              <div class="p-2">
                <h3 class="mb-2">
                  Metodo de pago
                </h3>
                <!-- Data -->
                <form
                  v-if="methods.includes('unique')"
                  :action="api"
                  method="POST"
                  target="_blank"
                  class="d-inline-block"
                >
                  <input
                    type="text"
                    name="token"
                    :value="token"
                    hidden
                  >
                  <input
                    type="text"
                    name="priceKey"
                    :value="item.priceKey"
                    hidden
                  >
                  <input
                    type="number"
                    name="typeOfItem"
                    value="5"
                    hidden
                  >
                  <input
                    type="text"
                    name="communitySlug"
                    :value="this.collective.slug"
                    hidden
                  >
                  <input
                    v-for="data in Object.keys(dataToSend)"
                    :key="dataToSend[data]"
                    type="text"
                    :value="dataToSend[data]"
                    :name="data"
                    hidden
                  >
                  <b-button
                    class="d-inline-block"
                    type="submit"
                    :disabled="disabledButtons"
                    variant="primary"
                    @click="handleNextStep"
                  >
                    Paypal
                  </b-button>
                </form>

                <form
                  v-if="methods.includes('unique')"
                  :action="api"
                  method="POST"
                  target="_blank"
                  class="d-inline-block"
                >
                  <input
                    type="text"
                    name="token"
                    :value="token"
                    hidden
                  >
                  <input
                    type="text"
                    name="priceKey"
                    :value="item.priceKey"
                    hidden
                  >
                  <input
                    type="number"
                    name="typeOfItem"
                    value="2"
                    hidden
                  >
                  <input
                    type="text"
                    name="communitySlug"
                    :value="this.collective.slug"
                    hidden
                  >
                  <input
                    v-for="data in Object.keys(dataToSend)"
                    :key="dataToSend[data]"
                    type="text"
                    :value="dataToSend[data]"
                    :name="data"
                    hidden
                  >
                  <b-button
                    class="ml-5 d-inline-block"
                    type="submit"
                    :disabled="disabledButtons"
                    variant="primary"
                    @click="handleNextStep"
                  >
                    Redsys
                  </b-button>
                </form>
                <form
                  v-if="methods.includes('subscription')"
                  :action="api"
                  method="POST"
                  target="_blank"
                  class="d-inline-block"
                >
                  <input
                    type="text"
                    name="token"
                    :value="token"
                    hidden
                  >
                  <input
                    type="text"
                    name="key"
                    :value="item.key"
                    hidden
                  >
                  <input
                    type="number"
                    name="typeOfItem"
                    value="1"
                    hidden
                  >
                  <input
                    type="text"
                    name="communitySlug"
                    :value="this.collective.slug"
                    hidden
                  >
                  <input
                    v-for="data in Object.keys(dataToSend)"
                    :key="dataToSend[data]"
                    type="text"
                    :value="dataToSend[data]"
                    :name="data"
                    hidden
                  >
                  <b-button
                    class="ml-5 d-inline-block"
                    type="submit"
                    :disabled="disabledButtons"
                    variant="primary"
                    @click="handleNextStep"
                  >
                    Stripe
                  </b-button>
                </form>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BCardText,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BAvatar,
  BFormTextarea,
  BImg,
  BBadge,
  BModal,
  BMedia,
  BMediaAside,
  BFormFile,
  BMediaBody,
  BFormGroup,
  BLink,
} from 'bootstrap-vue';
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { quillEditor } from 'vue-quill-editor';
import moment from 'moment';
import Service from '@/config/service-identifiers';
import { getImageResource } from '@/@core/utils/image-utils';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import Placeholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import Vue from 'vue';
import ForumPlaceholder from '@/assets/images/placeholders/light/forums.svg';

export default {
  name: 'OrderPurchase',
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    UserAvatar,
    quillEditor,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BAvatar,
    BFormTextarea,
    ClassifiersModal,
    CustomfieldsModal,
    BImg,
    BBadge,
    BModal,
    BMedia,
    BMediaAside,
    BFormFile,
    BMediaBody,
    BFormGroup,
    BLink,
  },
  data() {
    return {
      isLoading: true,
      editorOption: { placeholder: 'Escriba su idea al reto' },
      methods: [],
      dataToSend: {
        name: '',
        surname: '',
        phone: '',
        city: '',
        prov: '',
        address: '',
        mail: '',
        cif: '',
        postal: '',
      },
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      newComment: '',
      nextStep: false,
      disabled: false,
      disabledButtons: true,
      selectedAnswer: {},
      imageSrc: null,
      imageForum: null,
      forum: { name: '', description: '' },
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    api() {
      return process.env.VUE_APP_API_PAYMENT;
    },
    token() {
      return Vue.$cookies.get('accessToken');
    },
    type() {
      return this.$route.params.type;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    item() {
      return this.$store.getters.item('ticket', this.id);
    },
    Placeholder() {
      return Placeholder;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
  },
  methods: {
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleNextStep() {
      let nextStep = true;
      const toCompare = this.dataToSend;
      delete toCompare.surname;
      for (const row of Object.values(toCompare)) {
        if (row == '') {
          nextStep = false;
          break;
        }
      }
      if (nextStep) {
        // Pasa al siguiente paso de la compra
        this.nextStep = nextStep;
        this.disabledButtons = false;
        this.disabled = true;
      } else {
        // Mensaje de error
      }
    },
    // TODO: Remove this workaround. The BE should always return objects in descriptions
    getText(value) {
      if (!value) return;
      if (typeof value === 'object') return value[this.currentLocale] || Object.values(value)[0];

      const object = JSON.parse(value);
      return object[this.currentLocale] || Object.values(object)[0];
    },
    timestampToNow(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).fromNow();
    },
    async scrollBottom() {
      const element = document.getElementById('commentForm');
      element.scrollIntoView({ behavior: 'smooth' });
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    translatedFieldName(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field.name);
    },
    timestampToTime(timestamp) {
      return moment(timestamp).format('DD-MM-YYYY');
    },
    async fetchData() {
      if (this.type === 'community') {
        this.methods.push('subscription');
        await this.$store.dispatch('getItemsPayments', {
          itemType: 'subscriptionPlans',
          page: 1,
          requestConfig: {
            key: this.id,
          },
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.price-header {
  width: 100%;
  background: #28aab8;
  background-color: #abe9cd;
  text-align: center !important;
  background-image: linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%);
  padding-top: 100px;
  padding-bottom: 75px;
}

.price-header * {
  color: #fff;
  text-align: center !important;
  font-weight: bold;
}
.w-40 {
  width: 40%;
}
.w-26 {
  width: 26.8%;
}
</style>
